.dashboard{
    margin-left: 15%;
    padding:80px 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .dashboard-header{
    width: 100%;
    text-align: center;
  }
  .dashboard-content {
    width: 100%;
    padding: 20px;    
    min-height: 100vh;
    background-color: var(--surface-color);
    color: var(--default-color);
    border-radius: 8px;
  }
.portfolio-manager {
    padding: 20px;
}

.portfolio-header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.portfolio-body {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.portfolio-body h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.card {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.card-body {
    padding: 20px;
}
